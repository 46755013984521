var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "Tạo mới" : "Cập nhật",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Cấu hình" } },
            _vm._l(_vm.ConfigParamTrans, function (item, key) {
              return _c(
                "el-radio",
                {
                  key: key,
                  attrs: { disabled: _vm.status == "update", label: key },
                  model: {
                    value: _vm.form.param,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "param", $$v)
                    },
                    expression: "form.param",
                  },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Giá trị" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "value", $$v)
                  },
                  expression: "form.value",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ghi chú" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                },
                model: {
                  value: _vm.form.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }