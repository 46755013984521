var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm nhân viên - hàng loạt",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingValid,
              expression: "loadingValid",
            },
          ],
        },
        [
          _c("upload-excel", {
            staticStyle: { "margin-top": "12px" },
            attrs: { onSuccess: _vm.uploadSuccess, sheetName: _vm.sheetName },
          }),
          _c(
            "div",
            { staticStyle: { "margin-top": "12px" } },
            [
              _c(
                "el-link",
                {
                  attrs: { type: "primary", href: "/import-user-sample.xlsx" },
                },
                [
                  _c("i", { staticClass: "el-icon-download" }),
                  _vm._v(" Tải file excel mẫu "),
                ]
              ),
              _c("div", [
                _c("span", [_vm._v("Tổng dòng: ")]),
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.data.length)),
                ]),
              ]),
              _c("div", [
                _c("span", [_vm._v("Thành công:")]),
                _c(
                  "label",
                  { staticStyle: { color: "#67C23A" }, attrs: { for: "" } },
                  [_vm._v(" " + _vm._s(_vm.countSuccess))]
                ),
              ]),
              _c("div", [
                _c("span", [_vm._v("Thất bại:")]),
                _c(
                  "label",
                  { staticStyle: { color: "#F56C6C" }, attrs: { for: "" } },
                  [_vm._v(" " + _vm._s(_vm.errorList.length))]
                ),
              ]),
              _vm.errorList.length
                ? [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Danh sách dòng lỗi"),
                    ]),
                    _vm._l(_vm.errorList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            "max-height": "300px",
                            "over-follow": "scroll",
                          },
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.createData },
            },
            [_vm._v("Thêm dữ liệu")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }