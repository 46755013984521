"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.json.stringify.js");
var _router = require("@/router");
var _role = require("@/api/role");
var _index = _interopRequireDefault(require("../index"));
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  addRoutes: [],
  routes: [],
  constRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.routes = routes;
  },
  setConstRoutes: function setConstRoutes(state, value) {
    state.constRoutes = value;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (roles.includes("admin")) {
        accessedRoutes = _router.asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
      }
      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  setRoutesAccess: function setRoutesAccess(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      (0, _role.getPermission)(_index.default.getters.info.role.id).then(function (res) {
        var routesAccess = res.data.permissions;
        if (!routesAccess.length) {
          reject("Không có quyền truy cập");
          return;
        }
        commit("SET_ROUTES", routesAccess);
        var tmpConst = JSON.parse(JSON.stringify(_router.constantRoutes));
        routesAccess.forEach(function (element) {
          var parentPath = element.path.split("/")[1];
          var childPath = element.path.split("/")[2];
          tmpConst.forEach(function (route) {
            if (route.path == "/" + parentPath) {
              route.access = true;
              route.children.forEach(function (child) {
                if (child.path == childPath) {
                  child.access = true;
                }
              });
            }
          });
        });
        commit("setConstRoutes", tmpConst);
        resolve();
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};