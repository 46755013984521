"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformWard = exports.transformDistrict = exports.transformCity = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.trim.js");
var transformCity = exports.transformCity = function transformCity(text) {
  if (!text) {
    return "";
  }
  var city = text.replace(/(TP )|(Thành phố )|(Tỉnh )/, "");
  return city.replace(" - ", "-");
};
var transformDistrict = exports.transformDistrict = function transformDistrict(text) {
  if (!text) {
    return "";
  }
  return text.trim().replace(/(Quận )|(Thành phố )|(TP )|(Huyện )|(Thị trấn )|(Thị xã )/, "");
};
var transformWard = exports.transformWard = function transformWard(text) {
  if (!text) {
    return "";
  }
  return text.trim().replace(/(Phường )|(Xã )|(Thị trấn )/, "");
};