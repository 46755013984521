var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Thêm",
        visible: _vm.dialogVisible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.role,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Tên quyền" },
                model: {
                  value: _vm.role.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.role, "name", $$v)
                  },
                  expression: "role.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả" } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 2, maxRows: 4 },
                  type: "textarea",
                  placeholder: "Mô tả",
                },
                model: {
                  value: _vm.role.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.role, "description", $$v)
                  },
                  expression: "role.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.buttonLoading },
              on: { click: _vm.createData },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }