"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Account = _interopRequireDefault(require("./components/Account"));
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Profile",
  components: {
    Account: _Account.default,
    UserCard: _UserCard.default
  },
  data: function data() {
    return {
      user: {},
      activeTab: "account"
    };
  },
  methods: {}
};