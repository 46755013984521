"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/admin/driver/income/chart",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/charts/driver"));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: "/",
  redirect: "/tracking",
  hidden: true
}, {
  path: "/tracking",
  component: _layout.default,
  meta: {
    title: "Kết quả quét QRAccess",
    icon: "compass-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/tracking"));
      });
    },
    name: "tracking-index",
    meta: {
      title: "Kết quả quét QRAccess",
      permission: true
    }
  }, {
    path: "delete",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/tracking"));
      });
    },
    name: "tracking-delete",
    hidden: true,
    meta: {
      title: "Xóa xác thực",
      permission: true
    }
  }]
}, {
  path: "/address",
  component: _layout.default,
  meta: {
    title: "Sub-title",
    icon: "compass-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/address"));
      });
    },
    name: "address-index",
    meta: {
      title: "Sub-title",
      permission: true
    }
  }]
}, {
  path: "/original",
  component: _layout.default,
  meta: {
    title: "Tổ chứ",
    icon: "compass-fill",
    permission: true
  },
  permission: true,
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/company"));
      });
    },
    name: "original-index",
    meta: {
      title: "Tổ chức",
      permission: true
    }
  }]
}, {
  path: "/user",
  component: _layout.default,
  redirect: "/user/index",
  permission: true,
  meta: {
    title: "Manager",
    icon: "id-card-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/index"));
      });
    },
    name: "Technician",
    meta: {
      title: "Manager",
      permission: true
    }
  }, {
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "permission-role",
    meta: {
      title: "Quyền",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  permission: true,
  hidden: true,
  meta: {
    title: "Cá nhân",
    icon: "smile-fill",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      title: "Trang cá nhân",
      permission: false
    }
  }]
}, {
  path: "/config",
  component: _layout.default,
  redirect: "/config/index",
  hidden: true,
  meta: {
    title: "Cấu hình",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/index"));
      });
    },
    name: "config-index",
    meta: {
      title: "Cấu hình",
      permission: false
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;