"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chart = chart;
exports.create = create;
exports.driverApi = void 0;
exports.findOne = findOne;
exports.getDeposit = getDeposit;
exports.getList = getList;
exports.getWithdraw = getWithdraw;
exports.logout = logout;
exports.orders = orders;
exports.transaction = void 0;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
var driverApi = exports.driverApi = {
  profilePending: function profilePending(params) {
    return (0, _request.default)({
      url: "/drivers/pending",
      params: params
    });
  },
  approveProfile: function approveProfile(id) {
    return (0, _request.default)({
      url: "/drivers/".concat(id, "/approve"),
      method: "post"
    });
  },
  checkProfile: function checkProfile(id) {
    return (0, _request.default)({
      url: "/drivers/".concat(id, "/check"),
      method: "post"
    });
  },
  completeProfile: function completeProfile(id) {
    return (0, _request.default)({
      url: "/drivers/".concat(id, "/complete"),
      method: "post"
    });
  },
  update: function update(driverId, data) {
    return (0, _request.default)({
      url: "/drivers/".concat(driverId, "/update"),
      method: "post",
      data: data
    });
  },
  updateCode: function updateCode(driverId, data) {
    return (0, _request.default)({
      url: "/drivers/".concat(driverId, "/update/code"),
      method: "post",
      data: data
    });
  },
  updateProfile: function updateProfile(driverId, data) {
    return (0, _request.default)({
      url: "/drivers/".concat(driverId, "/profile/update"),
      method: "post",
      data: data
    });
  },
  findOne: findOne,
  listRef: function listRef(driverId, params) {
    return (0, _request.default)({
      url: "/drivers/".concat(driverId, "/ref"),
      params: params
    });
  },
  listPercent: function listPercent(driverId, params) {
    return (0, _request.default)({
      url: "/drivers/".concat(driverId, "/percent"),
      params: params
    });
  },
  reportProfile: function reportProfile(params) {
    return (0, _request.default)({
      url: "/drivers/report/profile",
      params: params
    });
  },
  getList: getList,
  remove: function remove(driverId) {
    return (0, _request.default)({
      url: "/drivers/".concat(driverId, "/delete"),
      method: "post"
    });
  }
};
function getList(listQuery) {
  return (0, _request.default)({
    url: "/drivers",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/drivers",
    method: "post",
    data: data
  });
}
function orders(driverId, params) {
  return (0, _request.default)({
    url: "/drivers/".concat(driverId, "/orders"),
    method: "get",
    params: params
  });
}
function findOne(driverId) {
  return (0, _request.default)({
    url: "/drivers/".concat(driverId),
    method: "get"
  });
}
function getDeposit(driverId) {
  return (0, _request.default)({
    url: "/drivers/".concat(driverId, "/deposits"),
    method: "get"
  });
}
function getWithdraw(driverId) {
  return (0, _request.default)({
    url: "/drivers/".concat(driverId, "/withdraws"),
    method: "get"
  });
}
function update(driverId, data) {
  return (0, _request.default)({
    url: "/drivers/".concat(driverId, "/update"),
    method: "post",
    data: data
  });
}
function chart(token) {
  return (0, _request.default)({
    url: "/orders/incomes/last30days",
    method: "get",
    params: token
  });
}
function logout(driverId) {
  return (0, _request.default)({
    url: "/drivers/".concat(driverId, "/logout"),
    method: "post"
  });
}
var transaction = exports.transaction = function transaction(id, query) {
  return (0, _request.default)({
    url: "/drivers/".concat(id, "/transactions"),
    params: query
  });
};