var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.$store.state.user.info.company &&
      _vm.$store.state.user.info.company.addresses.length == 1
        ? _c("div", { staticClass: "breadcrumb" }, [
            _c("div", { staticClass: "breadcrumb-item" }, [
              _c("div", { staticStyle: { margin: "0 10px" } }, [
                _c("img", {
                  attrs: {
                    width: "50",
                    src: _vm.$url.image(
                      _vm.$store.state.user.info.company.addresses[0].image
                    ),
                  },
                }),
              ]),
              _c("div", { staticClass: "breadcrumb-content" }, [
                _c("span", { staticStyle: { "white-space": "pre-wrap" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$store.state.user.info.company.addresses[0]
                        .description
                    )
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "white-space": "pre-wrap",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$store.state.user.info.company.addresses[0].name
                      )
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm theo tên, sđt",
                  size: "medium",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: { query: _vm.listQuery },
            on: {
              "change:address": _vm.handleChangeAddress,
              "fetch:cities": _vm.setCities,
              "fetch:districts": _vm.setDistricts,
              "fetch:wards": _vm.setWards,
            },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Tìm kiếm theo title"),
              ]),
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    size: "medium",
                    clearable: "",
                    placeholder: "Tất cả",
                  },
                  on: { change: _vm.fetchTableData },
                  model: {
                    value: _vm.listQuery.addressId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "addressId", $$v)
                    },
                    expression: "listQuery.addressId",
                  },
                },
                _vm._l(_vm.addresses, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.description || item.name,
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Thời gian")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                  size: "medium",
                },
                on: { change: _vm.fetchTableData },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Hợp lệ / không hợp lệ"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "Không chọn" },
                  on: { change: _vm.fetchTableData },
                  model: {
                    value: _vm.listQuery.isValid,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isValid", $$v)
                    },
                    expression: "listQuery.isValid",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Hợp lệ", value: true } }),
                  _c("el-option", {
                    attrs: { label: "Không hợp lệ", value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "medium",
                  },
                  on: { click: _vm.fetchTableData },
                },
                [_vm._v(" Tìm kiếm ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    size: "medium",
                    loading: _vm.loadingExport,
                  },
                  on: { click: _vm.handleExport },
                },
                [_vm._v(" Xuất excel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Họ tên: ")]),
                      _c("span", [_vm._v(_vm._s(row.customerName))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("SĐT: ")]),
                      _c("span", [_vm._v(_vm._s(row.customerPhone))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Công việc / MSSV: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.customerJob))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Chức danh / MSL: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.customerCompanyPosition))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Đơn vị: ")]),
                      _c("span", [_vm._v(_vm._s(row.customerCompanyName))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngành ĐK - Cấp bậc: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.customerInfo1))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ người xuất trình QR Access" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customerCity
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tỉnh thành: "),
                          ]),
                          _c("span", [_vm._v(_vm._s(row.customerCity.name))]),
                        ])
                      : _vm._e(),
                    row.customerDistrict
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Quận huyện: "),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(row.customerDistrict.name)),
                          ]),
                        ])
                      : _vm._e(),
                    row.customerWard
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Phường xã: "),
                          ]),
                          _c("span", [_vm._v(_vm._s(row.customerWard.name))]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.address.description) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sub-title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.address.name) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa điểm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      attrs: {
                        width: "80",
                        src: _vm.$url.image(row.address.image),
                      },
                    }),
                    _c("div", [
                      _c("span", [
                        _vm._v(" " + _vm._s(row.address.address) + " "),
                        row.address.ward
                          ? _c("span", [
                              _vm._v(
                                ", " + _vm._s(row.address.ward.nameWithType)
                              ),
                            ])
                          : _vm._e(),
                        row.address.district
                          ? _c("span", [
                              _vm._v(
                                ", " + _vm._s(row.address.district.nameWithType)
                              ),
                            ])
                          : _vm._e(),
                        row.address.city
                          ? _c("span", [
                              _vm._v(
                                ", " + _vm._s(row.address.city.nameWithType)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Người quét" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.staff
                      ? _c("div", [
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tên: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.staff.name))]),
                          ]),
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("SĐT: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.staff.phone))]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hợp lệ / Không hợp lệ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isValid
                      ? _c(
                          "label",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { for: "" },
                          },
                          [_vm._v(" Hợp lệ ")]
                        )
                      : _c(
                          "label",
                          {
                            staticStyle: { color: "#F56C6C" },
                            attrs: { for: "" },
                          },
                          [_vm._v(" Không hợp lệ ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Danh sách" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isMemberShip
                      ? _c(
                          "label",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { for: "" },
                          },
                          [_vm._v(" Đúng ")]
                        )
                      : _c(
                          "label",
                          {
                            staticStyle: { color: "#F56C6C" },
                            attrs: { for: "" },
                          },
                          [_vm._v(" Không ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giờ", prop: "createdAt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatDate(row.createdAt, "HH:mm")) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày", prop: "createdAt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.$formatDate(row.createdAt)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "GPS" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.lat && row.long
                      ? _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleLink(row)
                              },
                            },
                          },
                          [_vm._v(" Địa điểm ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm.visibleAction
            ? _c("el-table-column", {
                attrs: {
                  label: "Hành động",
                  align: "center",
                  width: "100",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Cập nhật",
                                placement: "top",
                                effect: "dark",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  icon: "el-icon-delete",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handelDelete(row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  996013700
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }