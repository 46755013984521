var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("Thông tin")])]
    ),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "pan-thumb",
            {
              attrs: {
                image: _vm.baseUrlMedia + _vm.user.avatar,
                height: "100px",
                width: "100px",
                hoverable: false,
              },
            },
            [
              _c("div", [_vm._v("Hello")]),
              _vm._v(" " + _vm._s(_vm.user.name) + " "),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
        _c("div", { staticClass: "user-role text-center text-muted" }, [
          _vm._v(_vm._s(_vm.user.role.name)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "user-bio" }, [
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "education" } }),
            _c("span", [_vm._v("Thông tin khác")]),
          ],
          1
        ),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Email:"),
            ]),
            _vm._v(" " + _vm._s(_vm.user.email) + " "),
          ]),
          _c("div", { staticClass: "text-muted" }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Số điện thoại:"),
            ]),
            _vm._v(" " + _vm._s(_vm.user.phone) + " "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }