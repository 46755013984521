"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clone = clone;
exports.cloneCityToDistrict = cloneCityToDistrict;
exports.create = create;
exports.findAllCity = findAllCity;
exports.findCity = findCity;
exports.findDistrict = findDistrict;
exports.findVehicleDistrict = findVehicleDistrict;
exports.updateVehicleCity = updateVehicleCity;
exports.updateVehicleDistrict = updateVehicleDistrict;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAllCity() {
  return (0, _request.default)({
    url: "/city"
  });
}
function findDistrict(cityCode) {
  return (0, _request.default)({
    url: "/city/".concat(cityCode, "/district")
  });
}
function findCity(params) {
  return (0, _request.default)({
    url: "/vehicleCity/city",
    method: "get",
    params: params
  });
}
function findVehicleDistrict(params) {
  return (0, _request.default)({
    url: "/vehicleCity/district",
    method: "get",
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/vehicleCity",
    method: "post",
    data: data
  });
}
function updateVehicleCity(data) {
  return (0, _request.default)({
    url: "/vehicleCity/city",
    method: "post",
    data: data
  });
}
function clone(data) {
  return (0, _request.default)({
    url: "/vehicleCity/clone/city",
    method: "post",
    data: data
  });
}
function cloneCityToDistrict(data) {
  return (0, _request.default)({
    url: "/vehicleCity/clone/district",
    method: "post",
    data: data
  });
}
function updateVehicleDistrict(data) {
  return (0, _request.default)({
    url: "/vehicleCity/district",
    method: "post",
    data: data
  });
}