"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeToSlug = changeToSlug;
exports.convertShortNumber = convertShortNumber;
exports.formatCurrency = formatCurrency;
exports.formatNumberVN = formatNumberVN;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.number.to-fixed.js");
function formatCurrency(n) {
  return new Intl.NumberFormat("Vi", {
    style: "currency",
    currency: "VND"
  }).format(n);
}
function formatNumberVN(n) {
  return new Intl.NumberFormat("Vi", {
    style: "decimal"
  }).format(n);
}
function changeToSlug(title) {
  var slug;

  //Đổi chữ hoa thành chữ thường
  slug = title.toLowerCase();

  //Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
  slug = slug.replace(/đ/gi, "d");
  //Xóa các ký tự đặt biệt
  slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, "");
  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, "-");
  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/\-\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-/gi, "-");
  slug = slug.replace(/\-\-/gi, "-");
  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = "@" + slug + "@";
  slug = slug.replace(/\@\-|\-\@|\@/gi, "");
  return slug;
}
function convertShortNumber(num) {
  var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var si = [{
    value: 1,
    symbol: ""
  }, {
    value: 1e3,
    symbol: "K"
  }, {
    value: 1e6,
    symbol: "M"
  }, {
    value: 1e9,
    symbol: "G"
  }, {
    value: 1e12,
    symbol: "T"
  }, {
    value: 1e15,
    symbol: "P"
  }, {
    value: 1e18,
    symbol: "E"
  }];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}