var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật địa chỉ" : "Thêm địa chỉ",
        visible: _vm.visible,
        width: _vm.modalWidth,
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "info", label: "Thông tin" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Title (Gợi ý: Thông tin đầy đủ trường, lớp… )",
                                prop: "description",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.form.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      "Sub-title (Gợi ý: Điểm danh, đăng ký, đăng ký mua…)",
                                    prop: "name",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Địa chỉ", prop: "address" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Tỉnh / thành phố",
                                prop: "cityId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "Tất cả",
                                  },
                                  on: { change: _vm.changeCity },
                                  model: {
                                    value: _vm.form.cityId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cityId", $$v)
                                    },
                                    expression: "form.cityId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Tất cả", value: 0 },
                                  }),
                                  _vm._l(_vm.cities, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Quận huyện",
                                prop: "districtId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "Tất cả",
                                  },
                                  on: { change: _vm.changeDistrict },
                                  model: {
                                    value: _vm.form.districtId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "districtId", $$v)
                                    },
                                    expression: "form.districtId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Tất cả", value: 0 },
                                  }),
                                  _vm._l(_vm.districts, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Phường xã", prop: "wardId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "Tất cả",
                                  },
                                  model: {
                                    value: _vm.form.wardId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "wardId", $$v)
                                    },
                                    expression: "form.wardId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Tất cả", value: 0 },
                                  }),
                                  _vm._l(_vm.wards, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Thời hạn QRCODE hết hạn (phút)",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.tokenExpireIn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "tokenExpireIn", $$v)
                                  },
                                  expression: "form.tokenExpireIn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giới hạn số lần khai báo trong ngày",
                                prop: "limitDay",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.limitDay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "limitDay", $$v)
                                  },
                                  expression: "form.limitDay",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giới hạn số lần khai báo trong tuần",
                                prop: "limitWeek",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.limitWeek,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "limitWeek", $$v)
                                  },
                                  expression: "form.limitWeek",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tổ chức", prop: "companyId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "" },
                              model: {
                                value: _vm.form.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "companyId", $$v)
                                },
                                expression: "form.companyId",
                              },
                            },
                            _vm._l(_vm.companies, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Trạng thái", prop: "isVisible" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-text": "Hiện",
                              "inactive-text": "Ẩn",
                            },
                            model: {
                              value: _vm.form.isVisible,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isVisible", $$v)
                              },
                              expression: "form.isVisible",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Hình ảnh địa điểm", prop: "image" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "upload-control" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticStyle: {
                                    width: "180px",
                                    height: "180px",
                                    margin: "0 auto",
                                  },
                                  attrs: {
                                    action: _vm.$baseUrl + "/staff/upload",
                                    "show-file-list": false,
                                    name: "file",
                                    drag: "",
                                    "on-success": _vm.handleAvatarSuccess,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    headers: { token: _vm.token },
                                  },
                                },
                                [
                                  _vm.form.image
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        staticStyle: {
                                          "object-fit": "contain",
                                          height: "100%",
                                          width: "100%",
                                        },
                                        attrs: {
                                          src: _vm.$url.image(_vm.form.image),
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "users", label: "Danh sách người dùng" } },
                [
                  _c("AddressMemberView", {
                    attrs: { addressId: _vm.form.id },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.tabName == "info"
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }