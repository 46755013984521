"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
var _chart = _interopRequireDefault(require("chart.js"));
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
var driver = _interopRequireWildcard(require("@/api/driver"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      chartData: []
    };
  },
  methods: {
    drawChart: function drawChart() {
      var canvas = document.getElementById("myChart");
      var ctx = canvas.getContext("2d");
      var gradientFill = ctx.createLinearGradient(0, 500, 0, 100);
      gradientFill.addColorStop(1, "rgba(63,59,18,1)");
      gradientFill.addColorStop(0, "rgba(63,59,18,0.2)");
      var myChart = new _chart.default(ctx, {
        type: "line",
        data: {
          labels: this.chartData.map(function (item) {
            return item.date;
          }),
          datasets: [{
            fill: true,
            backgroundColor: gradientFill,
            data: this.chartData.map(function (item) {
              return item.total;
            }),
            borderColor: "#ffd428",
            borderWidth: 3
          }]
        },
        options: {
          legend: {
            display: false
          },
          responsive: true,
          scales: {
            yAxes: [{
              id: "A",
              position: "left",
              gridLines: {
                display: false
              },
              ticks: {
                callback: function callback(value, index, values) {
                  return (0, _format.convertShortNumber)(value);
                },
                fontColor: "#fff",
                beginAtZero: true
              }
            }, {
              id: "B",
              position: "right",
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                fontColor: "#fff",
                beginAtZero: true
              },
              display: true,
              gridLines: {
                display: true,
                color: "#fff",
                borderDash: [10, 10]
              }
            }]
          }
        }
      });
    },
    getData: function getData() {
      var _this = this;
      var token = (0, _utils.getQueryObject)(this.$route.fullpath);
      driver.chart(token).then(function (res) {
        _this.chartData = res.data;
        _this.drawChart();
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};