var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.$devMode
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Thêm cấu hình")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Cấu hình", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.ConfigParamTrans[row.param]) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Giá trị", prop: "value" } }),
          _c("el-table-column", { attrs: { label: "Ghi chú", prop: "note" } }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("config-modal", {
        ref: "ConfigModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }