var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v(" Tìm kiếm ")]),
            _c("el-input", {
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                staticStyle: { transform: "translateY(10px)" },
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Thêm mới")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                staticStyle: { transform: "translateY(10px)" },
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Tên tổ chức", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Địa điểm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.addresses
                            .map(function (e) {
                              return e.name
                            })
                            .join(", ")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Giới hạn số lượng sub-title",
              prop: "limitAddress",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !row.limitAddress
                            ? "Không giới hạn"
                            : row.limitAddress
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", prop: "isVisible", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: row.isVisible ? "success" : "danger",
                          effect: "dark",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.isVisible ? "Hiện" : "Ẩn") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thao tác", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "110px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v(" Cập nhật ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "110px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" Xóa ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("CompanyModal", {
        ref: "CompanyModal",
        on: { submitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }