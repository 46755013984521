var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Lấy mã QR Code",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { "min-height": "200px" },
            },
            [
              _c("qr-code", {
                ref: "qr",
                attrs: { size: 500, text: _vm.qrCode },
              }),
              _c("div", { staticStyle: { "margin-top": "12px" } }, [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Tải xuống: "),
                  _c("i", {
                    staticClass: "el-icon-download",
                    staticStyle: {
                      color: "red",
                      "font-size": "15px",
                      cursor: "pointer",
                    },
                    on: { click: _vm.handleDownloadQrCode },
                  }),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }