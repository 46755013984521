"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/028_Fclass/028admin_qlkb/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configurationApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var configurationApi = exports.configurationApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: '/configuration'
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/configuration',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/configuration/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  }
};