var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { rules: _vm.rules, model: _vm.dataForm } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Mật khẩu cũ", prop: "oldPass" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.dataForm.oldPass,
              callback: function ($$v) {
                _vm.$set(
                  _vm.dataForm,
                  "oldPass",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "dataForm.oldPass",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mật khẩu mới", prop: "newPass" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.dataForm.newPass,
              callback: function ($$v) {
                _vm.$set(
                  _vm.dataForm,
                  "newPass",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "dataForm.newPass",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Nhập lại mật khẩu mới", prop: "reNewPass" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.dataForm.reNewPass,
              callback: function ($$v) {
                _vm.$set(
                  _vm.dataForm,
                  "reNewPass",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "dataForm.reNewPass",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loadingButton, type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(" Cập nhật ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }