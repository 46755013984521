var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pan-item",
      style: { zIndex: _vm.zIndex, height: _vm.height, width: _vm.width },
    },
    [
      _c("div", { staticClass: "pan-info" }, [
        _c(
          "div",
          { staticClass: "pan-info-roles-container" },
          [_vm._t("default")],
          2
        ),
      ]),
      _c("div", {
        staticClass: "pan-thumb",
        style: { backgroundImage: "url(" + _vm.image + ")" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }